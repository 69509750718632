.App {
  text-align: center;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

/*       FOR INPECT PAGE CSS          */

.modal-height-width {
  /* height: 100vh; */
  width: 90vw;
}

@media screen and (max-width: 480px) {
  .modal-height-width {
    height: 100vh;
    width: 100vw;
  }
}

.shadow-modal {
  -moz-box-shadow: 0 0 10px #5e5d5d;
  -webkit-box-shadow: 0 0 10px #5e5d5d;
  box-shadow: 0 0 10px #5e5d5d;
}

.shadow {
  -moz-box-shadow: inset 0 0 5px #000000;
  -webkit-box-shadow: inset 0 0 5px #000000;
  box-shadow: inset 0 0 5px #000000;
}

.shadow-submit {
  -moz-box-shadow: 0px 4px 12px #0000009A;
  -webkit-box-shadow: 0px 4px 12px #0000009A;
  box-shadow: 0px 4px 12px #0000009A;
}

.shadow-na {
  -moz-box-shadow: 0px 4px 8px #00000080;
  -webkit-box-shadow: 0px 4px 8px #00000080;
  box-shadow: 0px 4px 8px #00000080;
}

.shadow-selected-green {
  -moz-box-shadow: inset 0 0 5px #000000, 0 0 0 4px #ffffff, 0 0 0 8px #458B00;
  -webkit-box-shadow: inset 0 0 5px #000000, 0 0 0 4px #ffffff, 0 0 0 8px #458B00;
  box-shadow: inset 0 0 5px #000000, 0 0 0 4px #ffffff, 0 0 0 8px #458B00;
}

.shadow-selected-gray {
  -moz-box-shadow: inset 0 0 5px #000000, 0 0 0 4px #ffffff, 0 0 0 8px #dedede;
  -webkit-box-shadow: inset 0 0 5px #000000, 0 0 0 4px #ffffff, 0 0 0 8px #dedede;
  box-shadow: inset 0 0 5px #000000, 0 0 0 4px #ffffff, 0 0 0 8px #dedede;
}

.shadow-selected-red {
  -moz-box-shadow: inset 0 0 5px #000000, 0 0 0 4px #ffffff, 0 0 0 8px #DD0000;
  -webkit-box-shadow: inset 0 0 5px #000000, 0 0 0 4px #ffffff, 0 0 0 8px #DD0000;
  box-shadow: inset 0 0 5px #000000, 0 0 0 4px #ffffff, 0 0 0 8px #DD0000;
}

.shadow:hover {
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;
}

textarea:focus {
  border: 1px solid red !important;
}

.otp-input>input {
  margin: 4px !important;
  width: 45px !important;
  height: 64px !important;
  border: 1px solid #323232;
  border-radius: 6px;
  color: #323232;
  font-size: 3rem;
  font-weight: 100;
}

.icon-color-black {
  filter: brightness(0) saturate(100%) invert(47%) sepia(4%) saturate(6%) hue-rotate(50deg) brightness(91%) contrast(89%);
}

.icon-color-default {
  filter: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(3606%) hue-rotate(212deg) brightness(122%) contrast(87%);
}

.transform-span {
  transform: translate(-50%, -50%);
}

.transition-span {
  transition: all .3s;
}

.image-hover:hover>.span-hover {
  opacity: 1 !important;
}

.image-hover:hover>.span-hover {
  transform: scale(1.3);
  opacity: 1;
}

.loader-cls svg
{
  position: absolute;
  margin: auto;
  left: 40%;
  top: 30%;
  z-index: 9999;
}
.delete-icon
{
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0px;
  background: #d6d6d6;
  opacity: 0.9;
  padding-top: 3px;
    padding-bottom: 3px;
}
input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

/*
.shadowa
{
background-image: url('../src/assets/OK_Button_Pressed.svg');
background-size: cover;
height: 100px;
width: 100px;
}
button.shadowa:active {
  box-shadow: 0 5px #666;
  background-image: url('../src/assets/OK_Button.svg');
}
button.shadowa:hover{background-image: url('../src/assets/OK_Button.svg');}

button.shadowa:focus{background-image: url('../src/assets/OK_Button.svg');}

button.shadowa {
  border: 0;
  padding: 12px 40px;
  font-size: 18px;
  background: #4b00ff;
  color: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 25px 1px rgba(0,0,0,0.25);
  cursor: pointer;
  outline: 0;
  transition: 0.3s linear;
}
button.shadowa:active {
  transform: scale(0.85);
  box-shadow: 3px 2px 25px 1px rgba(0,0,0,0.25);
}

.shadow-selected-greena
{
  background-image: url('../src/assets/OK_Button.svg');
  background-size: cover;
  height: 130px;
width: 130px;
}
*/


/* Green Ok Button Class */
button.button-green {
  background-color: #11A70B !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #0000009A;
  height: 100px;
  width: 100px;
  color: #fff;
  cursor: pointer;
  border-radius: 100%;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

button.button-green:hover,
button.button-green:focus {
  background-color: #11A70B !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

button.button-green:active {
  background-color: #11A70B !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}



button.button-green-selected
{
  background-color: #11A70B !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  height: 100px;
  width: 100px;
  color: #fff;
  cursor: pointer;
  border-radius: 100%;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 0px 4px 12px #0000009A;
  border: 4px solid #FFFFFF;
  /* outline: 5px solid #11A70B; */
  box-shadow:
  0 0 0 1px #FFFFFF,
  0 0 0 5px #11A70B
}

/* Red Not Ok Button Class */
button.button-red {
  background-color: #DD0000 !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #0000009A;
  height: 100px;
  width: 100px;
  color: #fff;
  cursor: pointer;
  border-radius: 100%;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

button.button-red:hover,
button.button-red:focus {
  background-color: #DD0000 !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
   -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

button.button-red:active {
  background-color: #DD0000 !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}



button.button-red-selected
{
  background-color: #DD0000 !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
   height: 100px;
  width: 100px;
  color: #fff;
  cursor: pointer;
  border-radius: 100%;
  text-transform: uppercase;
  box-shadow: 0px 4px 12px #0000009A;
  border: 4px solid #FFFFFF;
  /* outline: 5px solid #DD0000;*/
    box-shadow:
    0 0 0 1px #FFFFFF,
    0 0 0 5px #DD0000

}

/* Gray NA Button Class */
button.button-gray {
  background-color: #F2F2F2 !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #0000009A;
  height: 70px;
  width: 70px;
  color: #919191;
  cursor: pointer;
  border-radius: 100%;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

button.button-gray:hover,
button.button-gray:focus {
  background-color: #F2F2F2 !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
   -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

button.button-gray:active {
  background-color: #F2F2F2!important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}



button.button-gray-selected
{
  background-color: #F2F2F2 !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  height: 70px;
  width: 70px;
  color: #919191;
  cursor: pointer;
  border-radius: 100%;
  text-transform: uppercase;
  box-shadow: 0px 4px 12px #0000009A;
  border: 4px solid #FFFFFF;
  /* outline: 5px solid #C6C6C6; */
  box-shadow:
  0 0 0 1px #FFFFFF,
  0 0 0 5px #C6C6C6

}


input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}