@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inspection-circle
{
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  background-color: #FFB000;
  width: 230px;
  height: 230px;
}
.submit-circle
{
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  background-color: #11A70B;
  width: 100px;
  height: 100px;
}
.notok-circle
{
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;;
  background-color: #DD0000;
  width: 100px;
  height: 100px;
}
.na-circle
{
  background: transparent radial-gradient(closest-side at 50% 50%, #00000000 0%, #00000000 85%, #00000034 100%) 0% 0% no-repeat padding-box;
  background-color: #F2F2F2;
  width: 70px;
  height: 70px;
}

.upload-icon-color
{
  filter: invert(62%) sepia(2%) saturate(7%) hue-rotate(340deg) brightness(94%) contrast(83%);
}